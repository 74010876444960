import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as CONST from "../components/const";
import MoreLink from "../components/morelink";
// import { useWindowPosition } from "../components/utils";
// import { Trail } from '../components/animation'
const bg_h = "../images/top/bg_h.png";
//const bg_v = "../images/top/bg_v.png";
const bg_v_2 = "../images/top/bg_v2.png";

const kv_h = "../images/top/kv_h.png";
const kv_v = "../images/top/kv_v.png";
const About = "../images/top/about.png";
const Business = "../images/top/business.png";
const Club = "../images/top/club.png";
const Employment = "../images/top/employment.png";
const BizImage = "../images/top/bizimage.png";
const BizImage_2 = "../images/top/bizimage_2.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
	const TABLE_ROW_CLASS = CONST.TABLE_ROW_CLASS + " xl:w-7/12 2xl:w-6/12";
	const TABLE_ROW_LIGHT_SPACE_CLASS = "w-full xl:w-4/12 2xl:w-5/12";
	const MENU_TITLE_CLASS = "text-xl sm:text-4xl md:text-md font-medium md:font-normal mt-8 mb-8 xl:mt-0 xl:mb-10"
	// const [open, set] = useState(true)

	// if (useWindowPosition() > 100) {
	// 	() => { set(false) };
	// }

	return (
		<Layout>
			<SEO
				description="社名の由来は脳波のα波。脳波のα波は、私たちが得意なことや好きなことに集中しているときに強く観測されます。アルファ・ウェーブでは、心も体もリラックスし、かつ集中して仕事と遊びをしたい。そう考えているのです。"
				title="Home"
			/>

			<section className={CONST.SECTION_P_AREA_CLASS} >
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.TOP_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.TOP_BACK_GROUND_PATTERN_CLASS} src={bg_v_2} />
				{/* <Trail open={open} className="my-auto"> */}
				<div className="w-full sm:my-10 xl:flex 2xl:mb-20 3xl:mb-28 leading-11 xl:leading-9 z-10 font-normal xl:text-base">
					<div className="xl:flex-1">
						<p className="text-md xl:text-base tracking-wider">ALPHA WAVE × SYSTEM</p>
						{/* Todo 本番には反映していない。テスト環境には反映済み */}
						{/* <p className="text-md xs:text-lg xl:text-2xl my-10">アルファ・ウェーブのITソリューションは、お客様の安全と利益、価値向上に貢献します。</p> */}
						<p className="text-2xl xs:text-3xl sm:text-6xl tracking-wider md:font-light my-5 sm:mb-20 xl:mt-8 xl:mb-16"><span className="inline-block">社名の由来は</span><span className="inline-block">脳波のα波</span></p>
						<p>脳波のα波は、</p>
						<p className="mb-5 sm:mb-6 xl:mb-4">私たちが得意なことや好きなことに集中しているときに強く観測されます。</p>
						<p>アルファ・ウェーブでは、心も体もリラックスし、</p>
						<p>かつ集中して仕事と遊びをしたい。そう考えているのです。</p>
					</div>
					<div className="xl:flex-1 sm:max-w-xl mb-10 z-10 relative">
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={" hidden sm:block "} src={BizImage} />
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={" sm:hidden "} src={BizImage_2} />
						{/* <p className="text-green-700 absolute bottom-10">私たちは社会に貢献する様々なＩＴ事業を行っています。</p> */}
					</div>
				</div>
				{/* </Trail> */}
			</section>

			<div className="bg-gradient-to-t from-gray-light xl:-mt-16 2xl:-mt-28 3xl:-mt-20">
				<section className={CONST.SECTION_AREA_REVERSE_CLASS}>
					<div data-aos="fade-right" className={CONST.TOP_KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS} >
						<div className="sm:px-5 py-4 xl:py-0">
							<h1 className={CONST.H1_CLASS}>企業理念</h1>
							<p className="my-16 lg:mb-10 text-md sm:text-xl xl:text-lg tracking-wider"><span className="inline-block">創業時から</span><span className="inline-block">継続している理念</span></p>
							<p className="text-base sm:text-lg xl:text-base tracking-wider md:tracking-widest sm:leading-11 xl:leading-9">当社は2024年3月で創業40年を迎えました。お客様からの信頼を得て、ご支援し続けることができたのは、当社技術者のヒューマンスキルの高さによるものと自負しております。その基盤となる創業当初から変わらないコンセプト、それは「しっかり働き、しっかり遊ぶ」です。</p>
						</div>
					</div>
					<div className={CONST.TOP_KEY_VISUAL_RIGHT_IMG_AREA_CLASS}>
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_h} />
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_v} />
					</div>
				</section>

				<section data-aos="fade-up" className={CONST.SECTION_P_AREA_CLASS + " xl:pb-0"}>
					<div className="w-full xl:w-1/12 py-8">
						<p className="text-xl md:text-md font-bold tracking-wide">Topics</p>
					</div>
					<div className={TABLE_ROW_LIGHT_SPACE_CLASS}></div>
					<div className={TABLE_ROW_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS + " xl:pl-5"}>2024.8.01</div>
						<div className={CONST.TABLE_TEXT_CLASS + " xl:pl-5"}><a className="underline" href="https://iit.or.jp/" target="_blank" rel="noreferrer">一般社団法人東京都情報産業協会に入会しました。</a></div>
					</div>
					<div className={TABLE_ROW_LIGHT_SPACE_CLASS}></div>
					<div className="w-full xl:w-1/12"></div>
					<div className={TABLE_ROW_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS + " xl:pl-5"}>2024.6.30</div>
						<div className={CONST.TABLE_TEXT_CLASS + " xl:pl-5"}><a className="underline" href="https://www.photowave.jp/sabani_s/" target="_blank" rel="noreferrer">第２５回サバ二帆漕レースで、当社のチームえすぷりは総合順位５位で完走いたしました。</a></div>
					</div>
					<div className={TABLE_ROW_LIGHT_SPACE_CLASS}></div>
					<div className="w-full xl:w-1/12"></div>
					<div className={TABLE_ROW_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS + " xl:pl-5"}>2024.6.18</div>
						<div className={CONST.TABLE_TEXT_CLASS + " xl:pl-5"}><a className="underline" href="https://blog.alphawave.co.jp/act/index.php?catid=1&blogid=1" target="_blank" rel="noreferrer">神奈川県情報サービス産業協会のCTF大会で、当社のチームが優勝いたしました。</a></div>
					</div>
					<div className={TABLE_ROW_LIGHT_SPACE_CLASS}></div>
				</section>

				<section className={CONST.SECTION_P_AREA_CLASS + " 2xl:-mt-20 3xl:-mt-24"}>
					<div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-10 2xl:gap-16">
						<div data-aos="fade-up" className="mb-10 xl:mb-0">
							<Link key='about' to='about'>
								<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.LINE_SPACING_PB_CLASS + " w-full"} src={About} />
								<h5 className={MENU_TITLE_CLASS}>私たちについて</h5>
								{/* <p className="text-xs">大規模な期間業務や、技術のトレンドに挑戦</p> */}
							</Link>
							<MoreLink link_to="/about/" add_class="" is_arrow_long="false" />
						</div>
						<div data-aos="fade-up" data-aos-delay="200" className="mb-10 xl:mb-0">
							<Link key='business' to='business'>
								<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.LINE_SPACING_PB_CLASS + " w-full"} src={Business} />
								<h5 className={MENU_TITLE_CLASS}>事業内容</h5>
								{/* <p className="text-xs">大規模な期間業務や、技術のトレンドに挑戦</p> */}
							</Link>
							<MoreLink link_to="/business/" add_class="" is_arrow_long="false" />
						</div>
						<div data-aos="fade-up" data-aos-delay="300" className="mb-10 xl:mb-0">
							<Link key='club' to='club'>
								<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.LINE_SPACING_PB_CLASS + " w-full"} src={Club} />
								<h5 className={MENU_TITLE_CLASS}><span className="inline-block">イベント・</span><span className="inline-block">クラブ活動</span></h5>
								{/* <p className="text-xs">大規模な期間業務や、技術のトレンドに挑戦</p> */}
							</Link>
							<MoreLink link_to="/club/" add_class="" is_arrow_long="false" />
						</div>
						<div data-aos="fade-up" data-aos-delay="400" className="mb-10 xl:mb-0">
							<Link key='employment' to='employment'>
								<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.LINE_SPACING_PB_CLASS + " w-full"} src={Employment} />
								<h5 className={MENU_TITLE_CLASS}>採用情報</h5>
								{/* <p className="text-xs">大規模な期間業務や、技術のトレンドに挑戦</p> */}
							</Link>
							<MoreLink link_to="/employment/" add_class="" is_arrow_long="false" />
						</div>
					</div>
				</section>
			</div>

			<section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS + " mt-16 mb-24 3xl:my-24"}>
				<div className="w-full">
					<Tabs>
						<TabList className="xl:pl-20 text-sm tracking-normal">
							<Tab selectedClassName="bg-gray-light" className="w-full sm:w-4/12 xl:w-2/12 text-center inline-block border border-gray-300 relative cursor-pointer py-5 px-5">田町駅から本社</Tab>
							<Tab selectedClassName="bg-gray-light" className="w-full sm:w-4/12 xl:w-2/12 text-center inline-block border border-gray-300 relative cursor-pointer py-5 px-5">麻布十番駅から本社</Tab>
							<Tab selectedClassName="bg-gray-light" className="w-full sm:w-4/12 xl:w-2/12 text-center inline-block border border-gray-300 relative cursor-pointer py-5 px-5">田町駅から三田センター</Tab>
						</TabList>
						<TabPanel>
							<iframe className="w-full h-128" src="https://embed.mapfan.com/map/points/35.645732548019,139.7477088417,%E7%94%B0%E7%94%BA%E9%A7%85%EF%BC%88%E5%B1%B1%E6%89%8B%E7%B7%9A%EF%BC%89,SCH,J,300,/35.649122201809,139.73967001344,%E3%82%A2%E3%83%AB%E3%83%95%E3%82%A1%E3%83%BB%E3%82%A6%E3%82%A7%E3%83%BC%E3%83%96,S333H,J,W86IT,/types/walk/settings/now,4,101/routes?c=35.64734580301409,139.74433632908585,16.722975196587033&amp;s=std,pc,ja&amp;p=none"></iframe>
						</TabPanel>
						<TabPanel>
							<iframe className="w-full h-128" src="https://embed.mapfan.com/map/points/35.6538421666622,139.73703922579455,%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%AF%E5%8C%BA%E9%BA%BB%E5%B8%83%E5%8D%81%E7%95%AA%EF%BC%94%E2%80%90%EF%BC%94%E2%80%90%EF%BC%91,,,,/35.649122201809,139.73967001344,%E3%82%A2%E3%83%AB%E3%83%95%E3%82%A1%E3%83%BB%E3%82%A6%E3%82%A7%E3%83%BC%E3%83%96,S333H,J,W86IT,/types/walk/settings/now,4,101/routes?c=35.65176631557182,139.73912208334377,16.399695943727323&amp;s=std,pc,ja&amp;p=none"></iframe>
						</TabPanel>
						<TabPanel>
							<iframe className="w-full h-128" src="https://embed.mapfan.com/map/points/35.645732548019,139.7477088417,%E7%94%B0%E7%94%BA%E9%A7%85%EF%BC%88%E5%B1%B1%E6%89%8B%E7%B7%9A%EF%BC%89,SCH,J,300,/35.647760928912,139.74185168743,%E4%B8%89%E7%94%B0%E3%83%92%E3%83%AB%E3%82%B5%E3%82%A4%E3%83%89%E3%83%93%E3%83%AB,SCAQC,J,HTQRV0,/types/walk/settings/now,4,101/routes?c=35.64685300147106,139.74548860274888,17.007368214503696&amp;s=std,pc,ja&amp;p=none"></iframe>
						</TabPanel>
					</Tabs>
				</div>
			</section>
		</Layout >
	);
}

export default IndexPage;
